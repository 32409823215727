<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Financeiro</h4>
          <div class="page-title-right">
            <b-button
              variant="success"
              v-b-tooltip.hover
              title="Realizar Saque"
              @click.prevent="showModal = !showModal"
            >
              <i class="bx bx-wallet font-size-16 align-middle mr-2"></i>
              Saque
            </b-button>
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'contas-bancarias' })"
              class="ml-2"
            >
              <i class="bx bx-wallet font-size-16 align-middle mr-2"></i>
              Conta Bancária
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading_saldo">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error_saldo">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div
        v-for="stat of statDataFinanceiro"
        :key="stat.id"
        class="col-lg-6 col-sm-6 col-xl-4"
      >
        <Stat
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
          :color="stat.color"
        />
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="transactions.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="card">
          <div class="card-body text-left">
            <h5>Transações</h5>
            <hr />
            <div class="table-responsive mb-0">
              <table class="table table-centered table-nowrap">
                <thead class="thead-light text-left">
                  <tr>
                    <th>ID</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Conta</th>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th>Descrição</th>
                  </tr>
                </thead>
                <tbody class="text-left">
                  <tr v-for="(data, index) in transactions" :key="index">
                    <td>
                      <a
                        href="javascript: void(0);"
                        class="text-body font-weight-bold"
                      >
                        {{ data.id }}
                      </a>
                    </td>
                    <td>{{ data.created_at | moment('DD/MM/YYYY') }} às {{ data.created_at | moment('HH:mm') }}</td>
                    <td>{{ data.value | currency('R$ ') }}</td>
                    <td>{{ data.account }}</td>
                    <td>
                      {{ data.type }}
                    </td>
                    <td>
                        <span class="badge badge-warning" v-if="data.status == 'created'">Criada</span>
                        <span class="badge badge-info" v-else-if="data.status == 'pending'">Pendente</span>
                        <span class="badge badge-success" v-else-if="data.status == 'succeeded'">Bem sucedida</span>
                        <span class="badge badge-success" v-else-if="data.status == 'confirmed'">Confirmada</span>
                        <span class="badge badge-warning" v-else-if="data.status == 'failed'">Falhou</span>
                        <span class="badge badge-danger" v-else-if="data.status == 'canceled'">Cancelada</span>
                        <span class="badge badge-dark" v-else>-</span>
                    </td>
                    <td>
                      {{ data.description }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <b-modal v-model="showModal" title="Realizar Saque" centered>
      <div class="row" v-if="saldo > 0">
        <div class="col-12">
          <h3 class="text-success text-center">{{saldo | currency('R$ ')}}</h3>
          <p class="text-center">valor disponível para saque</p>
          <hr />
        </div>
        <div class="col-12">
          <label>
            <span class="mr-1 text-danger">*</span>
            Valor para Saque
          </label>
          <b-input-group prepend="R$">
            <b-form-input
            v-model="form.value"
            :class="{ 'is-invalid': form && $v.form.value.$error }"
            placeholder="0,00"
            v-money="money"
            ></b-form-input>
          </b-input-group>
          <small>Informe o valor que deseja sacar.</small>
        </div>
        <div class="col-12 mt-3">
          <label>Conta Bancária</label>
           <multiselect
              v-model="account"
              :options="options_conta"
              class="helo"
              label="name"
             :custom-label="customLabel"
              placeholder="Conta Bancária"
              select-label="Selecionar"
              deselect-label="Remover"
              :loading="isLoading"
              @search-change="searchAccount"
                :class="{ 'is-invalid': $v.account.$error }"
            ></multiselect>
        </div>
        <div class="col-12 mt-3">
          <label>
            Descrição
          </label>
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            placeholder="Descrição..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
      </div>
      <div class="row" v-else>
          <div class="col-12">
              <div class="alert alert-warning text-center" role="alert">
                  Saldo insuficiente para operações.
              </div>
          </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="danger" @click="cancelar()">
          Cancelar
        </b-button>
        <b-button v-if="saldo > 0" @click.prevent="salvarDados()" variant="success">Realizar Saque</b-button>
      </template>
    </b-modal>
  </div>
</template>
<style>
.is-invalid .multiselect__tags {
  border-color: #f46a6a !important;
  border: 1px solid #f46a6a !important;
}
</style>
<script>
import Stat from '@/components/widgets/stat'
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import { required } from 'vuelidate/lib/validators'
export default {
  components: {
    Stat,
    Multiselect,
    InfiniteLoading,
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      showModal: false,
      options_conta: [],
      statDataFinanceiro: [
        {
          id: 1,
          tipo: 0,
          icon: 'bx bx-wallet',
          title: 'Saldo Disponível',
          value: 0,
          color: 'bg-success',
        },
        {
          id: 2,
          tipo: 0,
          icon: 'bx bx-wallet',
          title: 'Saldo Bloqueado',
          value: 0,
          color: 'bg-danger',
        },
        {
           id: 3,
          tipo: 0,
          icon: 'bx bx-wallet',
          title: 'Saldo Total',
          value: 0,
          color: 'bg-success',
        },
      ],
      account: null,
      form:{
        value: null
      },
      transactions: [],
      loading_saldo: true,
      erorr_saldo: false,
      loading: true,
      erorr: false,
      saldo: 0,
      filtro: {
        limit: 10,
        page: 1,
      },
      isLoading: true,
      filtroAccount:{
        limit: 10,
      },
    }
  },
   validations: {
    form: {
      value: { required },
    },
    account: {required}
  },
  created() {
    this.listarSaldo()
    this.listAccount()
  },
  methods: {
    ...mapActions('finance', ['ActionTransactionAll', 'ActionTransactionSaldo', 'ActionAccountAll', 'ActionTransictionCreate']),
    customLabel({ name, number, agency, digit }) {
      return `${name} - Conta: ${number} | Agência: ${agency}`
    },
    cancelar(){
      this.account = null,
      this.form = {
        value: null
      },
      this.showModal = false
    },
    salvarDados(){
      this.$v.$touch()
      if (!this.$v.$invalid) {
        //this.form.value = this.form.value.replace(/[^0-9]/g,'');  
        
        console.log(typeof this.saldo)
        console.log(typeof parseFloat(this.form.value))
        
        if(this.saldo >= parseFloat(this.form.value)){
          this.submitTransiction()
        }else{
          this.$swal({
          title: 'Atenção!',
          text: "Saldo insuficiente",
          icon: 'info',
        })
        }
      }
    },
    async submitTransiction(){
      try {
          this.form.account = this.account.name + " - Conta: " + this.account.number + " | Agência: "+this.account.agency+" | Dígito: "+this.account.digit
          this.form.type = "Saque (Transferência)"  
          this.form.account_id =  this.account.id    
          await this.ActionTransictionCreate(this.form).then(res=>{
            this.transactions.unshift(res.data.data)
            this.$swal({
              title: 'Sucesso',
              text: 'Ação realizada com sucesso!',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
            }).then((_) => {
              ;(this.account = null),
                (this.form = {
                  value: null,
                })
              this.showModal = false
              this.listarSaldo()
            })
          })
          
      } catch (e) {
          this.$swal({
          title: 'Erro!',
          text: "Não foi possível realizar a ação soicitada!",
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    infiniteHandler($state) {
      this.ActionTransactionAll(this.filtro).then(({ data }) => {
        if (data.data.list != null && data.data.list.length) {
          this.filtro.page += 1
          this.transactions.push(...data.data.list)
          $state.loaded()
        } else {
          $state.complete()
        }
        this.loading = false
        this.error = false
      })
    },
    async listAccount(){
      try {
        this.isLoading = true
        await this.ActionAccountAll(this.filtroAccount).then(res=>{
          this.options_conta = res.data.data.list
           this.isLoading = false
        })
      } catch (e) {
         this.isLoading = false
          this.options_conta = []
      }
    },
    searchAccount(e) {
      this.filtroAccount.search = e
      this.listAccount()
    },
    async listarSaldo(){
      try {
        this.loading_saldo = true
          await this.ActionTransactionSaldo().then(res=>{
            console.log(res.data.data)
            this.statDataFinanceiro[0].value = res.data.data.available
            this.statDataFinanceiro[1].value = res.data.data.blocked
            this.statDataFinanceiro[2].value = res.data.data.total
            this.saldo = res.data.data.available
            this.loading_saldo = false
            this.error_saldo = false
          })
      } catch (_) {
        this.saldo = 0
         this.loading_saldo = false
         this.error_saldo = false
      }
    }
  },
}
</script>


"transaction.created",
"transaction.canceled",
"transaction.capture.failed	",
"transaction.capture.succeeded	",
"transaction.charged_back",
"transaction.commission.succeeded",
"transaction.disputed",
"transaction.dispute.succeeded",
"transaction.pre_authorization.failed	",
"transaction.pre_authorization.succeeded	",
"transaction.pre_authorized	",
"transaction.reversed",
"transaction.succeeded",
"transaction.updated	",
"transaction.void.failed",
"transaction.void.succeeded",
"transaction.failed",
"buyer.transaction.canceled	",
"buyer.transaction.charged_back	",
"buyer.transaction.commission.succeeded	",
"buyer.transaction.created	",
"buyer.transaction.dispute.succeeded	",
"buyer.transaction.disputed	",
"buyer.transaction.failed	",
"buyer.transaction.pre_authorized	",
"buyer.transaction.reversed	",
"buyer.transaction.succeeded	",
"buyer.transaction.updated",
"plan.created",
"plan.deleted",
"plan.updated",
"subscription.created",
"subscription.active",
"subscription.deleted",
"subscription.expired",
"subscription.updated",
"subscription.suspended",
"subscription.paid",
"invoice.created",
"invoice.overdue",
"invoice.paid",
"invoice.refunded",
"receivable.created",
"receivable.paid",
"receivable.canceled",
"receivable.deleted",
"receivable.refunded",
"receivable.scheduled",
"transfer.created",
"transfer.updated",
"transfer.confirmed",
"transfer.succeeded",
"transfer.failed",
"transfer.canceled",
"transfer.delayed",
